<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h2 class="text-center my-4">Novi suradnik</h2>
            </b-col>
            <b-col md="12" class="flex-center">
                <b-form v-on:submit.prevent="createAssociate" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Ime:"
                                  label-for="name">
                        <b-form-input id="name" type="text" size="sm" name="name" required></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Adresa:"
                                  label-for="address">
                        <b-form-input id="address" type="text" size="sm" name="address" required></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Grad:"
                                  label-for="township_id">
                        <select id="township_id" class="form-control form-control-sm" name="township_id" required>
                            <option value="" selected disabled>Izaberite grad</option>
                            <option v-for="township in townships" :value="township.id">
                                {{ township.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Telefon:"
                                  label-for="phone">
                        <b-form-input id="phone" type="text" size="sm" name="phone"></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="ID Broj:"
                                  label-for="id_number">
                        <b-form-input id="id_number" type="text" size="sm" name="id_number"
                                      pattern="[0-9]{13}"></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Djelatnost:"
                                  label-for="activity_id">
                        <select id="activity_id" class="form-control form-control-sm" name="activity_id" required>
                            <option value="" selected disabled>Izaberite djelatnost</option>
                            <option v-for="activity in activities" :value="activity.id">
                                {{ activity.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="PDV Broj:"
                                  label-for="pdv_number">
                        <b-form-input id="pdv_number" type="text" size="sm" name="pdv_number" pattern="[0-9]{12}"
                                      placeholder="Ostavite prazno ako nije u sustavu PDV-a"></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Šifra:"
                                  label-for="code">
                        <b-form-input id="code" type="number" size="sm" name="code" maxlength="6"></b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Tip:"
                                  label-for="type">
                        <b-form-radio-group id="type" name="type">
                            <b-form-radio value="1">Kupac</b-form-radio>
                            <b-form-radio value="2">Dobavljač</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-btn type="submit" variant="primary" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                activities: null,
                townships: null
            }
        },
        methods: {
            createAssociate: function (e) {
                const formData = new FormData(e.target);
                formData.append('client_id', this.$parent.selectedClient.id);
                axiOsta.post(api.associates, formData).then(response => {
                        if (response.status === 201) {
                            this.$router.push('/associates/' + response.data.id);
                        }
                    }
                )
            }
        },
        created() {
            axiOsta.get(api.activities)
                .then(response => {
                    if (response.status === 200) {
                        this.activities = response.data
                    }
                });
            axiOsta.get(api.townships)
                .then(response => {
                    if (response.status === 200) {
                        this.townships = response.data
                    }
                })
        }
    }
</script>
